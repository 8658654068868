<template>
	<div class="app-main__inner">
		<div class="container rounded p-3 dm-sans-font">
			<div class="row">
				<div class="col-md-12 justify-center mb-3 rounded-lg mt-3">
					<div class="p-5 bg-white" v-if="orderDetails.data">
						<h3
							class="text-4xl text-center mb-3 font-semibold text-blue-700"
						>
							{{ $t("orderConfirmation") }}
						</h3>
						<div class="text-base text-gray-800">
							<div
								v-if="orderDetails.order_confirmation_text"
								v-html="orderDetails.order_confirmation_text"
							></div>
							<div v-else>
								{{ $t("thankYouOrder") }} <br />
								<b>{{
									orderDetails.data.seller | capitalizeNames
								}}</b>
								{{ $t("proceedOrder") }}
								<b
									><i>{{ orderDetails.email }}</i></b
								>
							</div>
						</div>
						<br />
						<div class="border-2 border-gray-600 rounded-lg mt-3">
							<div class="flex flex-col p-3">
								<div class="">
									<div class="flex justify-between mb-2">
										<span
											class="text-xl font-semibold uppercase"
											>{{ $t("orderNumber") }}</span
										>
										<span>{{
											orderDetails.data
												.in_house_order_number
										}}</span>
									</div>
									<div class="flex justify-between mb-2">
										<span
											class="text-xl font-semibold uppercase"
											>{{ $t("date") }}</span
										>
										<span>{{
											orderDetails.data.date
										}}</span>
									</div>

									<div class="flex justify-between mb-2">
										<span
											class="text-xl text-black-400 font-semibold uppercase"
											>{{ $t("amount") }}</span
										>
										<div>
											<p class="font-black text-right">
												{{ orderDetails.amount }}
												{{ currency }}
											</p>
											<!--
											<p class="w-full">
												{{ $t("vat") }}
												{{
													orderDetails?.data?.vat_amount?.toFixed(
														2
													)
												}}
												{{ currency }}
											</p>-->
										</div>
									</div>
								</div>
								<div>
									<div class="flex justify-between mb-3">
										<span class="text-xl font-semibold">{{
											$t("seller")
										}}</span>
										<span class="">{{
											orderDetails.data.seller
										}}</span>
									</div>
									<div class="flex justify-between mb-3">
										<span class="text-xl font-semibold">{{
											$t("buyer")
										}}</span>
										<span class="mt-1">{{
											orderDetails.data.buyer
										}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-5 bg-white" v-if="orderDetails.data">
						<div
							class="table-responsive rounded-lg bg-white border"
						>
							<table
								style="width: 100%"
								v-loading="loading"
								id="exampledestroy"
								class="table table-hover table-bordered"
							>
								<thead>
									<tr
										class="bg-white rounded p-1 border-b-2 shadow-sm"
									>
										<th class="text-xs">
											<span
												class="d-flex justify-content-between align-items-center"
												>{{ $t("articleNumber") }}
											</span>
										</th>
										<th class="text-xs">
											<div
												class="d-flex justify-content-between align-items-center"
											>
												{{ $t("description") }}
											</div>
										</th>
										<th class="text-xs">
											<div
												class="d-flex justify-content-between align-items-center"
											>
												{{ $t("quantity") }}
											</div>
										</th>
										<th class="text-xs">
											<div
												class="d-flex justify-content-between align-items-center"
											>
												{{ $t("salesPrice") }}
											</div>
										</th>
										<th class="text-xs">
											<div
												class="d-flex justify-content-between align-items-center"
											>
												{{ $t("discount") }}
											</div>
										</th>
										<th class="text-xs">
											<div class="d-flex justify-center">
												{{ $t("vat") }}
											</div>
										</th>
										<th class="text-xs">
											<div
												class="d-flex justify-content-between align-items-center"
											>
												{{ $t("total") }}
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									<order-confirmation-row
										v-for="(row, i) in orderDetails.data
											.order_rows"
										:key="i"
										:currency="currency"
										:row="row"
									/>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
import orderConfirmationRow from "./component/orderConfirmationRow";
import { mapGetters } from "vuex";

export default {
	name: "order-confirmation",
	components: {
		orderConfirmationRow,
	},
	data() {
		return {
			companyName: "",
			loading: false,
			orderDetails: {
				data: {
					in_house_order_number: "",
					date: "25/01/2023",
					seller: "",
					buyer: "",
					email: " ",
					amount: 100,
					order_rows: [],
				},
				order_confirmation_text: null,
			},
			dateParse: "",
			currency: "",
			order_confirmation_text: null,
		};
	},
	mounted() {
		this.companyName = window.localStorage.getItem("comp_name");
		this.orderDetails = JSON.parse(
			window.localStorage.getItem("orderDetails")
		);
		this.getCompanyUser();
		setTimeout(() => {
			if (!this.orderDetails) {
				this.$router.go(1);
			}
		}, 500);
	},
	methods: {
		getCompanyUser() {
			this.$store.dispatch("customer/getUserCompanyName").then((resp) => {
				if (resp.data.data.length > 0) {
					if (
						resp.data.data[0].apply_customer_currency_on_orders ===
						true
					) {
						if (
							resp.data.data[0].currency !== "" &&
							resp.data.data[0].currency !== null &&
							resp.data.data[0].currency !== undefined
						) {
							this.currency = resp.data.data[0].currency;
						}
					}
				}
			});
		},
	},
	computed: {
		...mapGetters({
			GET_LOCALE: "utility/GET_LOCALE_LANG",
		}),
	},
};
</script>

<style lang="scss" scoped>
.font-12px {
	font-size: 12px;
}
</style>
