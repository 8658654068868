<template>
	<tr>
		<td>
			<span>{{ row.article_number }}</span>
		</td>
		<td>
			<span class="font-12px">{{ row.description }}</span>
		</td>
		<td>
			<span class="font-12px">{{ row.delivered_quantity }}</span>
		</td>
		<td>
			<span class="font-12px">{{ row.price_excluding_vat }}</span>
		</td>
		<td>
			<span
				class="font-12px text-red-500"
				v-if="row?.discount && Number(row?.discount) > 0"
				>{{
					convertDiscountValueToPercentage(
						Number(row.discount),
						row?.discount_type,
						Number(row?.price)
					)
				}}%</span
			>
			<span v-else>-</span>
		</td>

		<td class="w-[15rem]">
			<div v-if="row.vat_amount">
				<span class="font-12px"> {{ row?.vat }}%</span>,
				<span class="">{{ row.vat_amount?.toFixed(2) }} row.currency </span>
			</div>
			<div v-else>-</div>
		</td>
		<td class="w-20">
			<span class="font-12px flex">{{ row.total_to_pay }} row.currency </span>
		</td>
	</tr>
</template>

<script>
import { convertDiscountValueToPercentage } from "@/utils/utils";

export default {
	name: "orderConfirmationRow",
	props: {
		row: {
			type: Object,
		},
		currency: {
			type: String,
			default: "",
		},
	},
	mounted() {
		this.percent = localStorage?.getItem("CURRENT_DISCOUNT_VALUE_B2B");
	},
	data() {
		return {
			percent: "",
			convertDiscountValueToPercentage,
		};
	},
	methods: {
		calVat: function (price_ink_vat) {
			try {
				const ex_vat_factor = 1 / (1 + this.row.vat);
				const ex_vat_price = price_ink_vat * ex_vat_factor;
				return price_ink_vat - ex_vat_price;
			} catch (e) {
				return 0;
			}
		},
	},
};
</script>

<style scoped></style>
